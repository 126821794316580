// @ts-nocheck
const resource = {
  "catalog.action.compare-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparez ce modèle"])},
  "catalog.action.view-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le modèle"])},
  "catalog-compare.action.back-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'aperçu"])},
  "catalog-compare.action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "catalog-compare.action.compare-models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer les modèles"])},
  "catalog-compare.intro.product-comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez Hangcha Belgium, nous vous offrons la possibilité de comparer facilement différentes machines. Que vous soyez à la recherche d'un chariot élévateur, d'un chariot à mât rétractable ou d'autres équipements industriels, notre fonction de comparaison en ligne vous aide à faire le bon choix pour votre entreprise."])},
  "catalog-compare.title.applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "catalog-compare.title.product-comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b><em>Comparaison</em></b> des produits"])},
  "catalog-compare.title.specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifications"])},
  "catalog-filters.action.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
  "catalog-filters.action.show-products": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afficher ", _interpolate(_named("Count")), " produits"])},
  "catalog-filters.label.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
  "catalog-filters.placeholder.search-by-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par nom"])},
  "catalog-filters.placeholder.selected-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur sélectionnée"])},
  "catalog-filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])},
  "catalog.message.warning.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun produit n'a été trouvé."])},
  "catalog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les <i><b>produits</b></i>"])},
  "common.action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "common.action.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'accueil"])},
  "form.hint.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionnel"])},
  "form.placeholder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être une adresse électronique."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire."])},
  "mail.layout.closing:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Team Hangcha"])},
  "mail.layout.footer:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangcha - xxx 123, 6666 xxxx, Belgium"])},
  "mail.layout.greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour,"])},
  "page.title.catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
  "page.title.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "pagination.action.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "pagination.action.previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])}
}
export default resource