// @ts-nocheck
const resource = {
  "catalog.action.compare-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergelijk dit model"])},
  "catalog.action.view-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk model"])},
  "catalog-compare.action.back-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar overzicht"])},
  "catalog-compare.action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
  "catalog-compare.action.compare-models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergelijk modellen"])},
  "catalog-compare.intro.product-comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij Hangcha Belgium bieden we u de mogelijkheid om verschillende machines eenvoudig met elkaar te vergelijken. Of u nu op zoek bent naar een heftruck, reachtruck of andere industriële apparatuur, onze online vergelijkingsfunctie helpt u bij het maken van de juiste keuze voor uw bedrijf."])},
  "catalog-compare.title.applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toepassingen"])},
  "catalog-compare.title.product-comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product <b><em>vergelijking</em></b>"])},
  "catalog-compare.title.specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specificaties"])},
  "catalog-filters.action.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "catalog-filters.action.show-products": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toon ", _interpolate(_named("Count")), " producten"])},
  "catalog-filters.label.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultaten"])},
  "catalog-filters.placeholder.search-by-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op naam"])},
  "catalog-filters.placeholder.selected-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerde waarde"])},
  "catalog-filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "catalog.message.warning.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen producten gevonden."])},
  "catalog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle <i><b>producten</b></i>"])},
  "common.action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
  "common.action.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar home"])},
  "form.hint.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optioneel"])},
  "form.placeholder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer"])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient een e-mail adres te zijn."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht."])},
  "mail.layout.closing:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Team Hangcha"])},
  "mail.layout.footer:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangcha - xxx 123, 6666 xxxx, Belgium"])},
  "mail.layout.greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo,"])},
  "page.title.catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanbod"])},
  "page.title.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "pagination.action.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
  "pagination.action.previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])}
}
export default resource