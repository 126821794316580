// @ts-nocheck
const resource = {
  "catalog.action.compare-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare this model"])},
  "catalog.action.view-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View model"])},
  "catalog-compare.action.back-to-overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to overview"])},
  "catalog-compare.action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "catalog-compare.action.compare-models": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare models"])},
  "catalog-compare.intro.product-comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Hangcha Belgium, we offer you the opportunity to easily compare different machines. Whether you are looking for a forklift, reach truck, or other industrial equipment, our online comparison tool helps you make the right choice for your business."])},
  "catalog-compare.title.applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "catalog-compare.title.product-comparison": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product <b><em>comparison</em></b>"])},
  "catalog-compare.title.specifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specifications"])},
  "catalog-filters.action.filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
  "catalog-filters.action.show-products": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show ", _interpolate(_named("Count")), " products"])},
  "catalog-filters.label.results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])},
  "catalog-filters.placeholder.search-by-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name"])},
  "catalog-filters.placeholder.selected-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected value"])},
  "catalog-filters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "catalog.message.warning.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No products found."])},
  "catalog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All <i><b>products</b></i>"])},
  "common.action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "common.action.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
  "form.hint.optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
  "form.placeholder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be an e-mail address."])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is mandatory."])},
  "mail.layout.closing:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Team Hangcha"])},
  "mail.layout.footer:hangcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hangcha - xxx 123, 6666 xxxx, Belgium"])},
  "mail.layout.greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello,"])},
  "page.title.catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Offer"])},
  "page.title.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "pagination.action.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "pagination.action.previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "test": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["test"])}
}
export default resource