import { defaultLocale } from "../config/canonicalSettings";
import en from "./generated/en";
import fr from "./generated/fr";
import nl from "./generated/nl";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    nl: nl,
    fr: fr,
    en: en,
  },
}));
